import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import { CLIENT_ENV } from '@/config/configuration';
import fetch from 'cross-fetch';

const httpLink = createHttpLink({
  uri: `${CLIENT_ENV().NEXT_PUBLIC_INCLINIC_GATEWAY_URL}/graphql`,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }) => {
  let willRetry = false;

  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const { message, locations, path, source, originalError, extensions } = error;
      if (message === 'Timeout has occurred' && extensions.code === 'INTERNAL_SERVER_ERROR') {
        willRetry = true;
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path} variables:${JSON.stringify(
          operation.variables,
        )}`,
      );
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (willRetry) {
    console.log('[GraphQL retry operation]');
    return forward(operation);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 10,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
  },
});

const client = new ApolloClient({
  link: from([retryLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
