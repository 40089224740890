import {
  AvailabilitySlotByIdDocument,
  CheckCouponValidityQueryResult,
  PaymentType,
  SpecialityByIdDocument,
  SpecialityChannel,
  WorkgroupByIdDocument,
} from '@/graphql/types';
import { ClinicAllowedPayments } from '@/store/createBookingInformationsSlice';
import { CheckoutFormFields, FormStep } from '@/store/createCheckoutSlice';
import client from './apolloClient';

export const formatPrice = ({ amount }: { amount?: null | number }) => {
  if (!amount) throw new Error('amount is null or undefined');
  if (isNaN(amount)) throw new Error('amount is NaN');
  return (amount / 100)
    .toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    })
    .replace(/\s/g, ' ')
    .trim();
};

export type CouponResponse = {
  amountSubtracted: number;
  newTotalAmount: number;
};

export const getClinicPaymentOptions = (availablePayments: PaymentType[]): ClinicAllowedPayments => {
  return {
    online: availablePayments?.includes(PaymentType.Online),
    onsite: availablePayments?.includes(PaymentType.Onsite),
    deferred: availablePayments?.includes(PaymentType.Deferred),
  };
};

export const calculateCouponDiscount = (
  coupon: CheckCouponValidityQueryResult['checkCouponValidity'],
  totalAmount: number,
): CouponResponse | null => {
  let amountToSubtract = 0;
  if (coupon?.fixed) {
    amountToSubtract = coupon.fixed;
  } else if (coupon?.percentage) {
    amountToSubtract = Math.floor((totalAmount / 10000) * coupon.percentage);
  }
  if (amountToSubtract > totalAmount) {
    return {
      amountSubtracted: amountToSubtract,
      newTotalAmount: 0,
    };
  } else {
    return {
      amountSubtracted: amountToSubtract,
      newTotalAmount: totalAmount - amountToSubtract,
    };
  }
};

/**
 * Generate a URL for the checkout page with the necessary parameters based on the input values.
 *
 * @param ambulatoryId String representing the ID of the ambulatory
 * @param startTime Number representing the start time of the appointment
 * @param availabilitySlotId String representing the ID of the availability slot
 * @param specialityTemplateId String representing the ID of the speciality template
 * @returns String representing the URL for the checkout page with the necessary parameters based on the input values.
 **/
type CheckoutUrlInput = {
  pathname: string;
  ambulatoryId?: null | string;
  startTime?: number;
  availabilitySlotId?: null | string;
  specialityId?: null | string;
};
export const getCheckoutURL = (input: CheckoutUrlInput) => {
  const checkoutUrl = `${input.pathname}?amb=${input?.ambulatoryId ?? ''}&slot=${input?.availabilitySlotId ?? ''}&start=${
    input?.startTime ?? ''
  }&spec=${input?.specialityId ?? ''}`;
  return checkoutUrl;
};

export const fetchCheckoutData = async (
  ambulatoryId: string,
  availabilitySlotId: string,
  startTime: string,
  specialityId: string,
  checkoutFormStep: FormStep,
  setBookingInformation: Function,
  updateCheckoutFormField: Function,
  setBookingInformationLoading: Function,
) => {
  if (ambulatoryId && availabilitySlotId && startTime && specialityId && checkoutFormStep !== FormStep.BookingConfirmation) {
    const { data: ambulatory } = await client.query({
      query: WorkgroupByIdDocument,
      variables: { id: ambulatoryId },
      fetchPolicy: 'no-cache',
    });

    const { data: availabilitySlot } = await client.query({
      query: AvailabilitySlotByIdDocument,
      variables: { id: availabilitySlotId },
    });

    const { data: speciality } = await client.query({
      query: SpecialityByIdDocument,
      variables: { id: specialityId },
    });

    const specialityPrice = availabilitySlot?.availabilitySlotById.price ?? speciality.specialityById.price;

    const clinicAllowedPayments = getClinicPaymentOptions(ambulatory?.workgroupById?.availablePayments ?? []);
    setBookingInformation({
      ambulatory: ambulatory?.workgroupById,
      speciality: { ...speciality.specialityById, price: specialityPrice },
      ambulatoryId: ambulatoryId,
      availabilitySlotId: availabilitySlotId,
      startTime: Number(startTime),
      specialityId: speciality.specialityById.id ?? '',
      doctorId: availabilitySlot?.availabilitySlotById.doctorId,
      clinicAllowedPayments: clinicAllowedPayments,
      reservationOrigin: undefined,
    });
    updateCheckoutFormField(
      'homeVisit',
      speciality.specialityById?.specialityChannels?.includes(SpecialityChannel.Online)
        ? false
        : ambulatory?.workgroupById?.domiciliaryCare ?? false,
    );
    updateCheckoutFormField(
      'isVideoConsultation',
      speciality?.specialityById?.specialityChannels?.includes(SpecialityChannel.Online) ?? false,
    );
    updateCheckoutFormField('userPaymentSelected', clinicAllowedPayments?.online ? PaymentType.Online : PaymentType.Onsite);
    updateCheckoutFormField('totalAmount', specialityPrice);
    setBookingInformationLoading(false);
  }
};

export const getUserBillingInfo = (checkoutForm: CheckoutFormFields) => {
  if (
    checkoutForm?.billingDistrict &&
    checkoutForm?.billingCity &&
    checkoutForm?.billingStreet &&
    checkoutForm?.billingStreetNumber &&
    checkoutForm?.billingCap
  ) {
    return {
      district: checkoutForm.billingDistrict,
      city: checkoutForm.billingCity,
      street: checkoutForm.billingStreet,
      streetNumber: checkoutForm.billingStreetNumber,
      cap: checkoutForm.billingCap,
    };
  } else if (
    checkoutForm.isDomiciliarySameAsBillingAddress &&
    checkoutForm?.homeVisitDistrict &&
    checkoutForm?.homeVisitCity &&
    checkoutForm?.homeVisitStreet &&
    checkoutForm?.homeVisitStreetNumber &&
    checkoutForm?.homeVisitCap
  ) {
    return {
      district: checkoutForm.homeVisitDistrict,
      city: checkoutForm.homeVisitCity,
      street: checkoutForm.homeVisitStreet,
      streetNumber: checkoutForm.homeVisitStreetNumber,
      cap: checkoutForm.homeVisitCap,
    };
  } else {
    return null;
  }
};
